import React from 'react';


const Logo = () => {
	return (
		<div className='text-7xl md:text-9xl mt-0 pt-5 bg-paletteColor2'>
			<h1 className='logoCustom text-paletteColor5'>LARUS</h1>
		</div>
	)
}

export default Logo;

import React from "react";
import businessData from "../content/BusinessPartners.json";

const BusinessPartners = () => {
	return (
		<section>
			<div className="text-center">
				<h2 className="text-5xl md:text-6xl mb-10 mt-20 bg-paletteColor3 py-5">Partners</h2>
			</div>
			<div className="max-w-[1200px] grid grid-cols-2 md:grid-cols-4 gap-5 justify-between mx-auto mb-10 px-2">
				{businessData &&
					businessData.map((data, index) => {
						return (
							<a
								className="grid grid-rows-1 relative"
								href={data.link}
								target="_blank"
								rel="noreferrer"
							>
								<img
									className="w-full h-full object-cover"
									src={data.imgPath}
									alt={data.companyName}
								/>
								<div className="bg-gray-400 opacity-0 hover:opacity-90 duration-300 w-full h-full z-20 absolute table overflow-hidden">
									<h4 className="table-cell align-middle text-center w-full h-full  px-2 text-white text-xl lg:text-3xl">
										{data.companyName}
									</h4>
								</div>
							</a>
						);
					})}
			</div>
		</section>
	);
};

export default BusinessPartners;

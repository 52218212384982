import React from 'react';
import data from "../content/PhotoAndText.json";

const PhotoAndText = () => {
	return (
		<section className='w-full mx-auto px-20 pt-20 pb-10'>

			<div className='md:flex w-full h-full md:h-[500px] xl:h-[700px] 2xl:h-[1000px] bg-paletteColor1 py-5 lg:py-20 mb-20'>
				<div className='md:w-[50%]'>
					<img
						className='w-full h-full object-cover px-5 lg:pl-20'
						src={data[0].imgPath}
						alt="test" />
				</div>
				<div className='md:w-[50%] px-2 md:px-3 py-1 text-sm md:text-md xl:text-2xl text-justify my-auto'>
					<p className='p-8 m-3 md:m-10 lg:max-w-[70%] lg:mx-auto bg-paletteColor2'>{data[0].text}</p>
				</div>
			</div>

			<div className='flex flex-col-reverse md:flex-row w-full h-full md:h-[500px] xl:h-[700px] 2xl:h-[1000px] mb-20 bg-paletteColor3 py-5 lg:py-20'>
				<div className='md:w-[50%] px-2 md:px-3 py-1 text-sm md:text-md xl:text-2xl text-justify my-auto'>
					<p className='p-8 m-3 md:m-10 lg:max-w-[70%] lg:mx-auto bg-paletteColor4'>{data[1].text}</p>
				</div>

				<div className='md:w-[50%]'>
					<img
						className='w-full h-full object-cover px-5 lg:pr-20'
						src={data[1].imgPath}
						alt="test" />
				</div>
			</div>

			<div className='md:flex w-full h-full md:h-[500px] xl:h-[700px] 2xl:h-[1000px] bg-paletteColor2 py-5 lg:py-20 mb-10'>
				<div className='md:w-[50%]'>
					<img
						className='w-full h-full object-cover px-5 lg:pl-20'
						src={data[2].imgPath}
						alt="test" />
				</div>

				<div className='md:w-[50%] px-2 md:px-3 py-1 text-sm md:text-md xl:text-2xl text-justify my-auto'>
					<p className='p-10 m-3 md:m-10 lg:max-w-[70%] lg:mx-auto bg-paletteColor1'>{data[2].text}</p>
				</div>
			</div>

		</section>
	)
}

export default PhotoAndText;

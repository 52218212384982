import { Routes, Route } from "react-router-dom"
import React from "react";
import Home from "./pages/Home"

function App() {
	return (
		<Routes>
			<Route path="/" index element={<Home />} />
			<Route path="/about" />
			<Route path="/studio" />
		</Routes>
	);
}


export default App;

import React from 'react'

const Footer = () => {
	return (
		<footer className='w-full text-[#f0ead6] bg-gray-400 text-sm py-3 px-2'>
			<div className='md:flex max-w-[1200px] justify-between mx-auto'>
				<p>Skovgaardsgade 5A, 8000 Aarhus C.</p>
				<p>info@larusaarhus.com</p>
				<p>CVR. nr: 38958860</p>
				<a href='https://moodycoding.com' target="_blank" rel="noreferrer">@moodycoding.com</a>
			</div>
		</footer>
	)
}

export default Footer;

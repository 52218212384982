import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import sliderData from "../content/Slider.json";
import ArrowRight from "../assets/icons/right-arrow.png";
import ArrowLeft from "../assets/icons/left-arrow.png";

const Slider = () => {
	const swiperRef = useRef();

	return (
		<>
			<section className="w-full mx-auto h-[250px] md:h-[500px] lg:h-[800px] 2xl:h-[1000px] relative z-0">
				<Swiper
					onSwiper={(swiper) => {
						swiperRef.current = swiper;
					}}
					loop={true}
					autoplay={{
						delay: 6000,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, EffectFade]}
					effect={"fade"}
					className="w-full mx-auto h-full bg-center bg-cover relative z-0"
				>
					{sliderData.map((data, index) => {
						return (
							<SwiperSlide key={index}>
								<img
									className="w-full h-full object-cover absolute z-10"
									src={data.imgPath}
									alt={data.altText}
								/>
								<div className="z-50 absolute bottom-[0px] right-[0px] bg-blue-300 py-3 px-5 bg-opacity-20" key={index}>
									<p className="text-white">{data.altText}</p>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>

				{/* SLIDER NAVIGATION PILE */}
				<figure className="left-3 absolute top-[50%] -translate-x-0 translate-y-[-50%] ease-in-out duration-300 z-50 opacity-75 hover:opacity-100 rounded-full p-1 text-white cursor-pointer">
					<img
						src={ArrowLeft}
						onClick={() => swiperRef.current.slidePrev()}
						className="w-full h-[50px] md:h-[120px] object-cover invert"
						alt="forrige billede"
					/>
				</figure>
				<figure className="right-3 absolute top-[50%] -translate-x-0 translate-y-[-50%] ease-in-out duration-300 z-50 opacity-75 hover:opacity-100 rounded-full p-1 text-white cursor-pointer">
					<img
						src={ArrowRight}
						onClick={() => swiperRef.current.slideNext()}
						className="w-full h-[50px] md:h-[120px] object-cover invert"
						alt="næste billede"
					/>
				</figure>
				{/* SLIDER NAVIGATION PILE SLUT */}
			</section>
		</>
	);
};

export default Slider;

import React from 'react';
import data from "../content/Text.json";

const Text = () => {
	return (
		<div className='max-w-[1200px] text-justify p-5 text-lg md:text-xl mx-auto py-20 px-20 bg-paletteColor4 my-20'>{data.text}</div>
	)
}

export default Text;

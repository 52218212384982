import React from "react";
import Logo from "../components/Logo";
import PhotoAndText from "../components/PhotoAndText";
import Slider from "../components/Slider";
import Text from "../components/Text";
import BusinessPartners from "../components/BusinessPartners";
import Footer from "../components/Footer";
import Quote from '../components/Quote'

function Home() {
	return (
		<div className="flex flex-col h-screen w-full overflow-x-hidden">
			<div className="overlay"></div>
			<header className="h-auto text-center">
				<Logo />
			</header>
			<section className="mb-auto">
				<PhotoAndText />
				<Quote />
				<Slider />
				<Text />
				<BusinessPartners />
			</section>
			<div className="h-auto bottom-0 text-center">
				<Footer />
			</div>
		</div>
	);
}

export default Home;
